import firebase from 'firebase/app'
import 'firebase/firestore'
// import 'firebase/auth'

import { FB_CONFIG } from './config'

let firestore
// let fireauth

const initFirebase = () => {
    firebase.initializeApp(FB_CONFIG)
    firestore = firebase.firestore()
    // fireauth = firebase.auth()
}

export {
    initFirebase,
    firestore
}

export const firebaseAddDoc = (collection, docData) => {
    return firestore.collection(collection).add(docData)
}
