import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import { fromJS } from 'immutable'
import configureStore from './store/configure-store'

import App from './components/app'
import ScrollToTop from './components/utils/scroll-to-top'
import * as serviceWorker from './serviceWorker'

import { initFirebase } from './firebase/firebase'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: process.env.NODE_ENV === 'production' ? 'GTM-MS6JMCZ' : ''
}

initFirebase()

let store = configureStore(fromJS({}))

TagManager.initialize(tagManagerArgs)

render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </BrowserRouter>
    </Provider>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
