import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import marketingData from '../reducers/index'

export default function configureStore(initState) {
    let devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
    return createStore(
        marketingData,
        initState,
        compose(
            applyMiddleware(thunk),
            devTools()
        )
    )
}
