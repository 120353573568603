import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import { routes } from '../constants/routes'

import Footer from './navs/footer'

import '../assets/scss/main.scss'

import PageLoader from './parts/page-loader'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes, faQuestion, faMinus, faSpinner, faPlayCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons'

const Home = lazy(() => import('./pages/home'))
const MadeForDealFlow = lazy(() => import('./pages/made-for-deal-flow'))
const Features = lazy(() => import('./pages/features'))
const Pricing = lazy(() => import('./pages/pricing'))
const Contact = lazy(() => import('./pages/contact'))
const RequestDemo = lazy(() => import('./pages/request-demo'))
const NotFound = lazy(() => import('./pages/not-found'))

library.add(faCheck, faTimes, faQuestion, faMinus, faSpinner, faPlayCircle, faWindowClose)

const App = () => {
    return (
        <Suspense fallback={PageLoader}>
            <div id="content">
                <Switch>
                    <Route path={routes.features} render={props => <Features {...props} />} />
                    <Route path={routes.madeForDealFlow} render={props => <MadeForDealFlow {...props} />} />
                    <Route path={routes.pricing} render={props => <Pricing {...props} />} />
                    <Route path={routes.contact} render={props => <Contact {...props} />} />
                    <Route path={routes.requestDemo} render={props => <RequestDemo {...props} />} />
                    <Route path={routes.index} exact render={props => <Home {...props} />} />
                    <Route render={props => <NotFound {...props} />} />
                </Switch>
            </div>
            <Footer />
        </Suspense>
    )
}

export default App
