export const routes = {
    index: '/',
    whyVisprop: '/why-visprop/',
    features: '/features/',
    madeForDealFlow: '/made-for-deal-flow/',
    pricing: '/pricing/',
    // team: '/team/',
    contact: '/contact/',
    requestDemo: '/request-demo/'
    // login: '/login/'
}
