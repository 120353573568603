import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <span>&copy; {new Date().getFullYear()} VisProp Analytics LLC</span>
        </div>
    )
}

export default Footer
