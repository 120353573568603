import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PageLoader = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '300px' }}>
            <FontAwesomeIcon icon="spinner" spin />
            <p>Loading content...</p>
        </div>
    )
}

export default PageLoader
